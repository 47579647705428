let ParserOptions = {
    attributeNamePrefix : "@",
    attrNodeName: "#attr", //default is 'false'
    textNodeName : "#text",
    // textNodeName : "",
    ignoreAttributes : false,
    ignoreNameSpace : false,
    allowBooleanAttributes : false,
    parseNodeValue : true,
    parseAttributeValue : false,
    trimValues: true,
    cdataTagName: "__cdata", //default is 'false'
    cdataPositionChar: "\\c",
    parseTrueNumberOnly: false,
    arrayMode: false, //"strict"
    attrValueProcessor: (val) => val,//default is a=>a
    tagValueProcessor : (val) => val, //default is a=>a
    stopNodes: ["parse-me-as-string"]
};
export default  ParserOptions;