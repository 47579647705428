import React, {Component, Fragment} from 'react';
import {Dialog, Transition} from "@headlessui/react";
import axios from "axios";
import {AiOutlineMail, AiOutlineRight, AiOutlineUser} from 'react-icons/ai';
import {GrSend} from 'react-icons/gr';


class ContactUs extends Component {
    constructor(props, context) {

        super(props, context);

        this.state = {
            isOpen: false,
            isError: false,
            errorMap: [],
            name:'',
            email:'',
            msg:'',
            subject:'',
        }
    }

    openModal = (codeLink) => {
        this.setState({codeLink: codeLink, isOpen: true});
    }
    closeModal = () => {
        // document.execCommand('copy',false,this.state.codeLink);
        this.setState({
            isOpen: false,
            isError: false,
            errorMap: [],
            name:'',
            email:'',
            msg:'',
            subject:'',
        });
    }

    submitMsg = () => {
        // document.execCommand('copy',false,this.state.codeLink);
        this.setState({isOpen: false, codeLink: ''});
    }

    changeValue = (event) => {
        let value = event.target.value;
        let id = event.target.id;
        let state = this.state;
        state[id] = value;
        state[id] = value;
        this.setState(state);

    }

    validate = () => {

     }

    submitMail = () => {
        let {name, email, msg, subject,isError, errorMap} = this.state;
        errorMap = [];
        isError = false;
        if (!name) {
            errorMap.push('name');
            isError = true;
        }
        if (!subject) {
            errorMap.push('subject');
            isError = true;
        }
        if (!email) {
            let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(email)) {
                errorMap.push('email');
                isError = true;
            }
        }

        if (!msg) {
            errorMap.push('msg');
            isError = true;
        }
        if(!isError){
            let formData = new FormData();    //formdata object

            formData.append('name', name);   //append the values with key, value pair
            formData.append('email', email);
            formData.append('subject', subject);
            formData.append('msg', msg);
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            axios.post(`/api/send-email`, formData, config).then((res) => {
                console.log(res);
                this.setState({
                    isOpen: false,
                    isError: false,
                    errorMap: [],
                    name:'',
                    email:'',
                    msg:'',
                    subject:'',
                })
            })
        }else{
            this.setState({...this.state,isError,errorMap})
        }
        console.log(name, email, msg, isError, errorMap)

    }

    render() {

        let {isOpen,name,email,msg ,isError,subject, errorMap} = this.state;
        return (
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto"
                    onClose={this.closeModal}
                    open={this.state.isOpen}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0"/>
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
              &#8203;
            </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >


                            <div
                                className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <div
                                    className="flex flex-row justify-between p-6 bg-white rounded-tl-lg rounded-tr-lg"
                                >
                                    <p className="font-semibold text-gray-800">Write Something to us!</p>

                                </div>
                                <div className="flex flex-col py-5 bg-gray-50">
                                    <form className="w-full max-w-sm mx-auto">


                                        <div className="relative text-gray-400 focus-within:text-gray-600">
                                            <div
                                                className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                <AiOutlineUser  className="text-lightIcon dark:text-gray-500 h-5 w-5" title={"Full Name..."}/>
                                            </div>
                                            <input
                                                   className="py-3 px-4 bg-white placeholder-gray-400 text-gray-900 rounded-lg shadow-md appearance-none w-full block pl-12 focus:outline-none"
                                                   placeholder="Full name..."
                                             onChange={this.changeValue}
                                             id={"name"}
                                             value={name}
                                            />
                                        </div>


                                        <div className="relative text-gray-400 focus-within:text-gray-600 mt-3 p-3">
                                            <div
                                                className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                <AiOutlineMail  className="text-lightIcon dark:text-gray-500 h-5 w-5" title={"Your email"}/>
                                            </div>
                                            <input onChange={this.changeValue}
                                                   id={"email"}
                                                   value={email}
                                                   className="py-3 px-4 bg-white placeholder-gray-400 text-gray-900 rounded-lg shadow-md appearance-none w-full block pl-9 focus:outline-none"
                                                   placeholder="you@example.com" />
                                        </div>

                                        <div className="relative text-gray-400 focus-within:text-gray-600 mt-3 p-3">
                                            <div
                                                className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                <AiOutlineRight  className="text-lightIcon dark:text-gray-500 h-5 w-5" title={"Subject"}/>
                                            </div>
                                            <input onChange={this.changeValue}
                                                   id={"subject"}
                                                   value={subject}
                                                   className="py-3 px-4 bg-white placeholder-gray-400 text-gray-900 rounded-lg shadow-md appearance-none w-full block pl-12 focus:outline-none"
                                                   placeholder="Write your single liner subject here..." />
                                        </div>

                                        <div className="relative text-gray-400 focus-within:text-gray-600 mt-3 p-3">

                                            <textarea onChange={this.changeValue}
                                                      id={"msg"}
                                                      value={msg}
                                                      rows={"6"}
                                                   className="py-3 px-4 bg-white placeholder-gray-400 text-gray-900 rounded-lg shadow-md appearance-none w-full block pl-12 focus:outline-none"
                                                   placeholder="Write your detailed message here..." />
                                        </div>
                                    </form>



                                    {/*{isError && errorMap.length > 0 && errorMap.filter(p => p === 'msg').length > 0 ?<p className="text-red-500 text-xs italic text-left">Please Provide Details.</p> :"" }*/}

                                </div>
                                <div
                                    className="flex flex-row items-center justify-between p-5 bg-white rounded-bl-lg rounded-br-lg"
                                >


                                    <button
                                        onClick={this.submitMail}
                                        className="px-4 py-2 text-white font-semibold bg-green-500 hover:bg-green-700 rounded  border-green-500 focus:border-green-700 w-full inline-flex items-center">
                                        <GrSend  className="text-lightIcon dark:text-gray-500 h-5 w-5 float-left pr-1 mr-2" title={"Send Message"}/>
                                        <span>Send Message</span>
                                    </button>




                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>

        );
    }
}

export default ContactUs;