import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import VirtualTreeComponent from "./VTreeDemo";
// import VirtualTreeComponent2 from "./VTreeDemo2";
import {CodeBeautifier} from "./CodeBeautifier";
import InputModes from "./InputModes";
import ContactUs from "./ContactUs";

function App() {


  return (
    <div className="App">


      {/*<NavBarTailwind />*/}
        <Router>



            <Switch>
                <Route path="/tree" component={VirtualTreeComponent} />
                <Route path="/contact" component={ContactUs} />
                <Route path="/json"
                       render={(props) => <CodeBeautifier
                           inputMode={InputModes.JSON.id}
                           content={InputModes.JSON.content}
                                                          {...props} />} />
                <Route path="/"
                       render={(props) => <CodeBeautifier

                                                          inputMode={InputModes.XML.id}
                                                          content={InputModes.XML.content}
                                                          {...props} />} />
            </Switch>
        </Router>




    </div>
  );
}

export default App;
