import React, {Fragment} from "react";
import {Menu, Transition} from '@headlessui/react'
import {ChevronDownIcon} from '@heroicons/react/solid'

import {BiCollapse, BiCut, BiExpand, BiPlayCircle, BiRedo, BiSave, BiShareAlt, BiUndo} from 'react-icons/bi';
import {AiOutlineCopy, AiOutlineMinus, AiOutlinePlus} from 'react-icons/ai';
import {EditorConfig} from "./Themes";
import CONFIG from "./config";
import ResultModes from "./ResultModes";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ToolBarDemo(props) {

    let config = CONFIG.getConfig();
    const [font,setFont] = React.useState(config.fontSize ? config.fontSize :'12px');
    const [mode,setMode] = React.useState(config.resultMode && config.resultMode.desc ? config.resultMode.desc :ResultModes.XML.desc);

    React.useEffect(() => {

        props.onToolBarMount(
            [
                {key:'changeFont',func:setFont},
                {key:'changeToolBarMode',func:setMode}
            ]
        );
    }, []);

    const updateFont = (e) => {
        let dataset = e.target.dataset;
        setFont(dataset.id);
        props.splitter.current.changeEditorSize(dataset.id);
    }
    return (
        <div className="flex w-full pt-1">

            <div className="flex w-2/4">
                {/*Clear Button*/}
                <div className="cursor-pointer py-1 pl-1 pr-2 has-tooltip" onClick={props.cutData}>
                    {/* <FaBeer /> */}
                    <BiCut  className="text-lightIcon dark:text-gray-500 h-5 w-5" title={"Cut"}/>
                    {/* <span className='tooltip'>Clear</span> */}
                </div>
                {/*Copy Button*/}
                <div className="cursor-pointer p-1 px-2" onClick={props.copyData}>
                    <AiOutlineCopy className="text-lightIcon dark:text-gray-500 h-5 w-5" title={"Copy"}/>
                </div>
                {/*Expand*/}
                <div className="cursor-pointer p-1 px-2" onClick={props.expand}>
                    <BiExpand  className="text-lightIcon dark:text-gray-500 h-5 w-5" title={"Formatted"}/>
                </div>
                {/*Minified*/}
                <div className="cursor-pointer p-1 px-2" onClick={props.minified}>
                    <BiCollapse  className="text-lightIcon dark:text-gray-500 h-5 w-5" title={"Minified"}/>
                </div>
                <div className="cursor-pointer p-1 px-2" onClick={props.expandInput}>
                    <AiOutlinePlus  className="text-lightIcon dark:text-gray-500 h-5 w-5" title={"Expand"}/>
                </div>
                <div className="cursor-pointer p-1 px-2" onClick={props.collapseInput}>
                    <AiOutlineMinus  className="text-lightIcon dark:text-gray-500 h-5 w-5" title={"Collapse"}/>
                </div>
                <div className="cursor-pointer p-1 px-2" onClick={props.undo}>
                    <BiUndo  className="text-lightIcon dark:text-gray-500 h-5 w-5" title={"Undo"}/>
                </div>
                <div className="cursor-pointer p-1 px-2" onClick={props.redo}>
                    <BiRedo  className="text-lightIcon dark:text-gray-500 h-5 w-5" title={"Redo"}/>
                </div>
                <Menu as="div" className="relative ">
                    {({ open }) => {
                        let fontMap = EditorConfig.font.value.map(font => {
                            return ( <Menu.Item onClick={updateFont} data-id={font.name} key={font.id}>
                                {({active}) => (
                                    <span
                                        // href="#"
                                        className={classNames(
                                            active ? 'hover:bg-gray-200 dark:hover:bg-black text-gray-600' : '' +
                                            'block px-4 py-2 text-sm text-lightIcon text-left '

                                        )}
                                    >
                                    {font.desc}</span>
                                )
                                }
                            </Menu.Item>);
                        });
                        return (
                            <>
                                <div>
                                    <Menu.Button
                                        className="bg-white dark:bg-DarkBG flex hover:text-gray-900 pt-0.5 px-2.5 focus:outline-none">
                                        <span
                                            className="text-lightIcon dark:text-gray-500 dark:hover:bg-gray-600 hover:bg-gray-100 text-sm">Font {font}</span>
                                        <ChevronDownIcon
                                            className={classNames(
                                                open ? 'text-gray-600' : 'text-lightIcon',
                                                'mt-1 h-4 w-4 group-hover:text-gray-500'
                                            )}
                                            aria-hidden="true"
                                        />
                                    </Menu.Button>
                                </div>
                                <Transition
                                    show={open}
                                    as={Fragment}
                                >
                                    <Menu.Items
                                        static
                                        className="origin-top-right z-50 absolute left-0 mt-2 w-48 shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none
                                        dark:bg-DarkBG cursor-pointer"
                                    >
                                        {fontMap}
                                    </Menu.Items>
                                </Transition>
                            </>
                        );
                    }}
                </Menu>
                <div className="cursor-pointer p-1 px-2" onClick={() => props.saveContent(false)}>
                    <BiSave  className="text-lightIcon dark:text-gray-500 h-5 w-5" title={"Save"}/>
                </div>
                <div className="cursor-pointer p-1 px-2" onClick={props.shareContent}>
                    <BiShareAlt  className="text-lightIcon dark:text-gray-500 h-5 w-5" title={"Share"}/>
                </div>
                <div id="playButton" className="cursor-pointer p-1 px-2" onClick={props.renderData}>
                    <BiPlayCircle  className="text-green-700  h-5 w-5" title={"Play"}/>
                </div>

                <Menu as="div" className="ml-3 relative p-1 px-2">
                    {({ open }) => (
                        <>
                            <div>
                                <Menu.Button className="bg-white dark:bg-DarkBG flex focus:outline-none focus:ring-1 focus:ring-offset-2 hover:text-gray-900 focus:ring-gray-800">
                                    <span className="text-lightIcon dark:text-gray-500 dark:hover:bg-gray-600 hover:bg-gray-100 text-sm">{mode}</span>
                                    <ChevronDownIcon
                                        className={classNames(
                                            open ? 'text-lightIcon' : 'text-lightIcon',
                                            'mt-0.5 h-4 w-4 group-hover:text-gray-500'
                                        )}
                                        aria-hidden="true"
                                    />
                                </Menu.Button>
                            </div>
                            <Transition
                                show={open}
                                as={Fragment}
                            >
                                <Menu.Items
                                    static
                                    className="origin-top-right z-50 absolute left-0 mt-2 w-32 shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-DarkBG cursor-pointer"
                                >
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="#"
                                                className={classNames(
                                                    active ? 'bg-gray-100' : '',
                                                    'block px-4 py-2 text-sm text-lightIcon text-left hover:bg-gray-200 dark:hover:bg-black'
                                                )}
                                                onClick={() => {
                                                    props.xmlProcess(setMode,ResultModes.XML.desc);
                                                    // setMode(ResultModes.XML.desc);
                                                }}
                                            >
                                                {ResultModes.XML.desc}</a>
                                        )}
                                    </Menu.Item>

                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="#"
                                                className={classNames(
                                                    active ? 'bg-gray-100' : '',
                                                    'block px-4 py-2 text-sm text-lightIcon text-left hover:bg-gray-200 dark:hover:bg-black'
                                                )}
                                                onClick={() => {
                                                    props.jsonProcess(setMode,ResultModes.JSON.desc)
                                                    // setMode(ResultModes.JSON.desc);
                                                }}
                                            >
                                                {ResultModes.JSON.desc}</a>
                                        )}
                                    </Menu.Item>

                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="#"
                                                className={classNames(
                                                    active ? 'bg-gray-100' : '',
                                                    'block px-4 py-2 text-sm text-lightIcon text-left hover:bg-gray-200 dark:hover:bg-black'
                                                )}
                                                onClick={() => {
                                                    props.treeProcess(setMode,ResultModes.TREE.desc);
                                                    // setMode(ResultModes.TREE.desc);
                                                }}
                                            >
                                                {ResultModes.TREE.desc}</a>
                                        )}
                                    </Menu.Item>
                                </Menu.Items>
                            </Transition>
                        </>
                    )}
                </Menu>

            </div>

            <div className="flex w-2/4 justify-end">
                <div className="cursor-pointer p-1 px-2" onClick={props.expandResults}>
                    <BiExpand  className="text-lightIcon dark:text-gray-500 h-5 w-5" title={"Expand"}/>
                </div>
                <div className="cursor-pointer p-1 px-2" onClick={props.minifiedResults}>
                    <BiCollapse  className="text-lightIcon dark:text-gray-500 h-5 w-5" title={"Collapse"}/>
                </div>
            </div>
        </div>
    );
}