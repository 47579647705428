const getConfig = () => {
    return localStorage && localStorage.getItem("config") ? JSON.parse(localStorage.getItem("config")) : {};
}

const updateConfig = (config) => {
    let stringify = JSON.stringify(config);
    localStorage && localStorage.setItem("config",stringify);
}

const CONFIG = {getConfig,updateConfig}
export default CONFIG;