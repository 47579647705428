export const EditorConfig = {

    themes : {
        id:'THEME',
        desc:'Editor Theme',
        value: [
            {isDark:false,"name":"chrome","desc": "Chrome"},
            {isDark:false,"name":"clouds","desc": "Clouds"},
            {isDark:false,"name":"crimson_editor","desc": "Crimson Editor"},
            {isDark:false,"name":"dawn","desc": "Dawn"},
            {isDark:false,"name":"dreamweaver","desc": "Dreamweaver"},
            {isDark:false,"name":"eclipse","desc": "Eclipse"},
            {isDark:false,"name":"github","desc": "GitHub"},
            {isDark:false,"name":"iplastic","desc": "IPlastic"},
            {isDark:false,"name":"solarized_light","desc": "Solarized Light"},
            {isDark:false,"name":"textmate","desc": "TextMate"},
            {isDark:false,"name":"tomorrow","desc": "Tomorrow"},
            {isDark:false,"name":"xcode","desc": "Xcode"},
            {isDark:false,"name":"kuroir","desc": "Kuroir"},
            {isDark:false,"name":"katzenmilch","desc": "KatzenMilch"},
            {isDark:false,"name":"sqlserver","desc": "SQL Server"},
            {isDark: true,"name":"ambiance","desc": "Ambiance"},
            {isDark: true,"name":"chaos","desc": "Chaos"},
            {isDark: true,"name":"clouds_midnight","desc": "Clouds Midnight"},
            {isDark: true,"name":"dracula","desc": "Dracula"},
            {isDark: true,"name":"cobalt","desc": "Cobalt"},
            {isDark: true,"name":"gruvbox","desc": "Gruvbox"},
            {isDark: true,"name":"gob","desc": "Green on Black"},
            {isDark: true,"name":"idle_fingers","desc": "idle Fingers"},
            {isDark: true,"name":"kr_theme","desc": "krTheme"},
            {isDark: true,"name":"merbivore","desc": "Merbivore"},
            {isDark: true,"name":"merbivore_soft","desc": "Merbivore Soft"},
            {isDark: true,"name":"mono_industrial","desc": "Mono Industrial"},
            {isDark: true,"name":"monokai","desc": "Monokai"},
            {isDark: true,"name":"nord_dark","desc": "Nord Dark"},
            {isDark: true,"name":"pastel_on_dark","desc": "Pastel on dark"},
            {isDark: true,"name":"solarized_dark","desc": "Solarized Dark"},
            {isDark: true,"name":"terminal","desc": "Terminal"},
            {isDark: true,"name":"tomorrow_night","desc": "Tomorrow Night"},
            {isDark: true,"name":"tomorrow_night_blue","desc": "Tomorrow Night Blue"},
            {isDark: true,"name":"tomorrow_night_bright","desc": "Tomorrow Night Bright"},
            {isDark: true,"name":"tomorrow_night_eighties","desc": "Tomorrow Night 80s"},
            {isDark: true,"name":"twilight","desc": "Twilight"},
            {isDark: true,"name":"vibrant_ink","desc": "Vibrant Ink"}

        ]
    },
    font : {
        id:'FONTSIZE',
        desc:'Font Size',
        value: [
            {id:"1","name":"12px","desc": "12px"},
            {id:"2","name":"14px","desc": "14px"},
            {id:"3","name":"16px","desc": "16px"},
            {id:"4","name":"18px","desc": "18px"},
            {id:"5","name":"20px","desc": "20px"},
            {id:"6","name":"22px","desc": "22px"}
        ]
    },
    keyBinding : {
        id:'KEYBINDING',
        desc:'Key Binding',
        value: [
            {"name":"","desc": "Ace"},
            {"name":"vim","desc": "Vim"},
            {"name":"emacs","desc": "Emacs"},
            {"name":"sublime","desc": "Sublime"},
            {"name":"vscode","desc": "VSCode"}
        ]
    }
};