import {ToastContainer} from "react-toastify";
import {useToasts} from "react-toast-notifications";
import React from "react";

export function NotificationComp(props) {

    const { addToast, removeAllToasts,toastStack } = useToasts();

    const checkNewToast = (data,value,option) => {
        // addToast(error.message, { appearance: 'error' });
        // console.log(toastStack)
        // if(toastStack && toastStack.length > 0){
        removeAllToasts()
        // }
        data.forEach(d => {
            addToast(d.text,{...d.options});
        })
    };
    const removeAllToast = () => {
        if(toastStack && toastStack.length > 0) {
            removeAllToasts()
        }
    };
    React.useEffect(() => {

        props.onToastMount(
            [
                {key:'addToast',func:checkNewToast},
                {key:'removeAllToasts',func:removeAllToast},
                {key:'toastStack',toastStack},
            ]
        );
    }, []);
    return <div className="ml-5">
        <ToastContainer
            position="top-right"
            // autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    </div>;
}